<!-- eslint-disable no-tabs -->
<template>
<!-- ***custom top bar offcanvas html*** -->
<div id="offCanvas" class="offcanvas-container">
	<div class="offcanvas-inner">
			<div class="offcanvas-sidebar">
				<aside class="widget author_widget">
						<h3 class="widget-title">{{ 'customizedTour' | translate }}</h3>
						<div class="widget-content text-center">
							<div class="profile">
									<div class="text-content">
										<p>{{ 'customizedTourInfo' | translate }}</p>
									</div>
									<br>
									<contact-form :messagePlaceholder="'customizedMessage'"/>
									<div class="socialgroup">
										<ul>
											<li>
                          <a :href="social.facebook" target="_blank">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                           <a :href="social.instagram" target="_blank">
                              <i class="fab fa-instagram" aria-hidden="true"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="social.youtube" target="_blank">
                              <i class="fab fa-youtube" aria-hidden="true"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="social.tripadvisor" target="_blank">
                              <i class="fab fa-tripadvisor" aria-hidden="true"></i>
                           </a>
                        </li>
										</ul>
									</div>
							</div>
						</div>
				</aside>
				<aside class="widget widget_text text-center">
						<h3 class="widget-title">{{ 'contactUs' | translate }}</h3>
						<div class="textwidget widget-text">
							<ul>
									<li>
										<a href="tel:+38762376719">
												<i aria-hidden="true" class="fa fa-phone"></i>
												+38762 376 719
										</a>
									</li>
									<li>
										<a href="mailtop:info@visitjablanica.com">
												<i aria-hidden="true" class="fa fa-envelope"></i>
												info@visitjablanica.com
										</a>
									</li>
									<li>
										<i aria-hidden="true" class="fa fa-map-marker"></i>
										Trg Oslobođenja SP 32 <br>
										88420, Jablanica <br>
										Bosna i Hercegovina
									</li>
							</ul>
						</div>
				</aside>
			</div>
			<a @click.stop="hideSidebar" class="offcanvas-close">
				<i class="fas fa-times"></i>
			</a>
	</div>
	<div @click.stop="hideSidebar" class="overlay"></div>
</div>
<!-- ***custom top bar offcanvas html*** -->
</template>
<script>

import ContactForm from '@/components/widgets/ContactForm.vue';

export default {
  name: 'CustomizeTour',

  components: {
    ContactForm,
  },

  data() {
    return {
      selectedTour: '',
      email: '',
      personNo: 0,
      startDate: null,
    };
  },

  computed: {
    tours() {
      return this.$store.getters.tours || [];
    },
    toursDropdown() {
      return this.tours.map((tour) => ({
        tourId: tour.tourId,
        name: tour.name,
        nameEn: tour.nameEn,
      }));
    },
  },

  methods: {
    hideSidebar() {
      this.$store.dispatch('setCustomizeTourSidebar', false);
    },
  },
};
</script>

<style>
  /* .contact-from-wrap {
    border-radius: 12px;
    padding: 16px;
  } */
</style>
