import { ActionTree } from 'vuex';
import { LanguagesState as State, Language } from '@/types';

const actions: ActionTree<State, any> = {
  setLanguageData({ commit }, { data, languageKey }: { data: Language, languageKey: string }) {
    commit('setLanguageData', { data, languageKey });
  },
  setCurrentLanguage({ commit }, languageKey: string) {
    commit('setCurrentLanguage', languageKey);
  },
};

export default actions;
