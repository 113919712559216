<template>
  <div ref="main">
    <Header />
    <router-view />
    <Footer />
    <customize-tour v-if="showCustomizeTour" />
  </div>
</template>

<script>
import Header from '@/components/elements/Header';
import Footer from '@/components/elements/Footer';
import CustomizeTour from '@/components/widgets/CustomizeTour';

export default {
  name: 'MainComponent',

  metaInfo: {
    // Title tag
    title: 'Visit Jablanica | Hiking, Kayaking, and Camping Tours in Bosnia and Herzegovina',
    // Description tag
    meta: [
      {
        name: 'description',
        content: 'Experience the beauty of Bosnia and Herzegovina with Jablanica Tourist Agency\'s hiking, kayaking, and camping tours. Explore the stunning natural landscapes of Jablanica and beyond with our expert guides.',
      },
      // Keywords tag
      {
        name: 'keywords',
        content: 'Jablanica, Bosnia and Herzegovina, tourist agency, tours, hiking, kayaking, camping, nature, adventure, mountains, Prenj, Cvrsnica, Neretva',
      },
      // Open Graph tags
      {
        property: 'og:title',
        content: 'Visit Jablanica | Hiking, Kayaking, and Camping Tours in Bosnia and Herzegovina',
      },
      {
        property: 'og:description',
        content: 'Experience the beauty of Bosnia and Herzegovina with Jablanica Tourist Agency\'s hiking, kayaking, and camping tours. Explore the stunning natural landscapes of Jablanica and beyond with our expert guides.',
      },
      {
        property: 'og:image',
        content: 'https://visitjablanica.com/img/flogo.e2e4be52.png',
      },
      {
        property: 'og:url',
        content: 'https://visitjablanica.com',
      },
      // Canonical tag
      {
        rel: 'canonical',
        href: 'https://visitjablanica.com',
      },
    ],
  },

  components: {
    Header,
    Footer,
    CustomizeTour,
  },

  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },
  created() {
    this.$store.dispatch('fetchSliderImages');
    this.$store.dispatch('fetchRecommendedTours');
    this.$store.dispatch('fetchTours').then(() => {
      this.$nextTick(() => {
        this.onPageLoad();
      });
    });
    this.$store.dispatch('getFoodPackages');
    this.$store.dispatch('getActivityLevels');
    this.$store.dispatch('getActivities');
    this.$store.dispatch('getBlogs');
    this.$store.dispatch('getGalleryImages');
  },
  destroyed() {
    // window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    showCustomizeTour() {
      return this.$store.getters.showCustomizeTour;
    },
  },
  methods: {
  },
};
</script>
