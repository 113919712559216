<template>
  <div id="page" class="page">
    <MainComponent />
  </div>
</template>

<script>
import MainComponent from '@/components/pages/MainComponent';

export default {
  components: {
    MainComponent,
  },
  computed: {
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || 'Visit Jablanica';
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import '../../assets/vendors/bootstrap/css/bootstrap.min.css';
@import '../../assets/vendors/fontawesome/css/all.min.css';
@import '../../assets/css/style.css';

#app {
margin: 0px;
padding: 0px;
overflow: hidden;
}
</style>
