/**
 * Main vuex actions
 * @packageDocumentation
 * @category Store
 */
import { ActionTree, ActionContext } from 'vuex';
import axios, { Canceler } from 'axios';
import { RootState as State } from '@/types';

let cancel: Canceler | undefined;
const BASE_URL = 'https://api-dev.visitjablanica.com/api/';
// const BASE_URL = 'http://localhost:5000/api/';

const actions: ActionTree<State, any> = {
  fetchSliderImages({ getters, commit }) {
    axios.get(`${BASE_URL}Users/GetHomePhotosForSlider`).then(({ data }) => {
      commit('setSliderImages', data);
      return data;
    });
  },
  fetchRecommendedTours({ getters, commit }) {
    axios.get(`${BASE_URL}Tour/GetRecomendedTour?PageNumber=${1}&PageSize=${3}`).then(({ data }) => {
      commit('setRecommendedTours', data);
      return data;
    });
  },
  fetchTours({ getters, commit }) {
    axios.get(`${BASE_URL}Tour/GetTours?PageNumber=${1}&PageSize=${10}`).then(({ data }) => {
      commit('setTours', data);
      return data;
    });
  },
  getTourById({ getters, commit }, id) {
    axios.get(`${BASE_URL}Tour/GetTour/${id}`).then(({ data }) => {
      commit('addTourOnFlatTours', data);
      return data;
    });
  },
  setCurrentTourId({ getters, commit }, id) {
    commit('setCurrentTourId', id);
  },

  addTourOnFlatTours({ getters, commit }, tour) {
    commit('addTourOnFlatTours', tour);
  },

  getFoodPackages({ getters, commit }) {
    axios.get(`${BASE_URL}Tour/GetFoodPackages`).then(({ data }) => {
      const flatObject = {};
      data.forEach((p) => {
        flatObject[p.foodPackageId] = p;
      });
      commit('setFoodPackages', flatObject);
      return data;
    });
  },

  getActivityLevels({ getters, commit }) {
    axios.get(`${BASE_URL}Tour/GetActivityLevel`).then(({ data }) => {
      const flatObject = {};
      data.forEach((a) => {
        flatObject[a.activityLevelId] = a;
      });
      commit('setActivityLevels', flatObject);
      return data;
    });
  },

  getActivities({ getters, commit }) {
    axios.get(`${BASE_URL}Tour/GetActivities`).then(({ data }) => {
      const flatObject = {};
      data.forEach((a) => {
        flatObject[a.activityId] = a;
      });
      commit('setActivities', flatObject);
      return data;
    });
  },
  getBlogs({ getters, commit }) {
    axios.get(`${BASE_URL}Blogs/GetActiveBlogs`).then(({ data }) => {
      const flatObject = {};
      data.forEach((b) => {
        flatObject[b.blogId] = b;
      });
      commit('setFlatBlogs', flatObject);
      commit('setBlogs', data);

      return data;
    });
  },
  getBlogById({ getters, commit }, id) {
    axios.get(`${BASE_URL}Blogs/GetBlogById?id=${id}`).then(({ data }) => {
      commit('setCurrentBlog', data);
      return data;
    });
  },
  setCurrentBlogId({ getters, commit }, id) {
    commit('setCurrentBlogId', id);
  },
  getGalleryImages({ getters, commit }) {
    axios.get(`${BASE_URL}Gallery/GetActivePhotos`).then(({ data }) => {
      commit('setGalleryImages', data);
      return data;
    });
  },

  createReview({ getters, commit }, { tourId, review }) {
    commit('localAddReview', review);
    return axios.post(`${BASE_URL}Tour/CreateReview/${tourId}`, review)
      .then((response) => response.data);
  },

  setCustomizeTourSidebar({ getters, commit }, value) {
    commit('setCustomizeTourSidebar', value);
  },
};

export default actions;
