export default {
  methods: {
    convertDate(datetimeStr) {
      // Parse the datetime string into a date object
      const dateObj = new Date(datetimeStr);

      // Get the day, month, year, hours and minutes
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');

      // Format the date and time string
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
  },
};
