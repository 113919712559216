export default {
  computed: {
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },

    isCurrentLanguageBs() {
      return this.currentLanguage === 'bs';
    },

    isCurrentLanguageEn() {
      return this.currentLanguage === 'en';
    },
  },
  methods: {
    translate(key) {
      return this.$store.getters.translate(key);
    },
    changeLanguage(languageKey) {
      this.$store.dispatch('setCurrentLanguage', languageKey);
    },
  },
};
