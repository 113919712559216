import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import getters from './store/getters';

Vue.use(Router);

const router = new Router({
  // mode: 'hash',
  mode: 'history',
  linkActiveClass: 'menu-active',
  linkExactActiveClass: 'menu-active',
  routes: [
    {
      path: '*',
      name: 'notfound',
      component: () => import(/* webpackChunkName: "notFound" */'./components/pages/NotFound.vue'),
    },
    // -
    {
      path: '/',
      name: '',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Home.vue'),
      meta: { guest: true },
    },
    // Home page
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Home.vue'),
      meta: { guest: true },
    },
    // Tours
    {
      path: '/tours',
      name: 'tours',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/TourList.vue'),
      meta: { guest: true },
    },
    // Tour
    {
      path: '/tour/:id',
      name: 'tour',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/TourDetails.vue'),
      meta: { guest: true },
    },
    // Blogs
    {
      path: '/blogs',
      name: 'blogs',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Blogs.vue'),
      meta: { guest: true },
    },
    // Blog
    {
      path: '/blog/:id',
      name: 'blog',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/BlogDetails.vue'),
    },
    // Gallery
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Gallery.vue'),
      meta: { guest: true },
    },
    // About
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/AboutUs.vue'),
      meta: { guest: true },
    },
    // Contact
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "home" */'./components/pages/Contact.vue'),
      meta: { guest: true },
    },
  ],
});

/* router.beforeEach((to, from, next) => {
  next();
}); */

export default router;
