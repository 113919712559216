import { MutationTree } from 'vuex';
import Vue from 'vue';
import { LanguagesState as State, Language } from '@/types';

const mutations: MutationTree<State> = {
  /** Sets current language abbreviation */
  setCurrentLanguage(state, languageKey: string) {
    if (languageKey !== '') {
      state.currentLanguage = languageKey;
    }
  },
  /** Sets language data to store for passed language key and translations. */
  setLanguageData(state, { data, languageKey }: { data: Language, languageKey: string }) {
    Vue.set(state.translations, languageKey, data);
  },
};

export default mutations;
