<template>
<!-- ***site footer html start form here*** -->
<footer id="colophon" class="site-footer footer-primary">
            <div class="top-footer">
               <div class="container">
                  <div class="upper-footer">
                     <div class="row">
                        <div class="col-lg-3 col-sm-6">
                           <aside class="widget widget_text">
                              <div class="footer-logo">
                                 <a href="index.html">
                                  <img src="../../assets/images/flogo.png" alt=""></a>
                              </div>
                              <div class="textwidget widget-text">
                                 {{ 'footerDescription' | translate }}
                              </div>
                           </aside>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <aside class="widget widget_latest_post widget-post-thumb">
                              <h3 class="widget-title">{{ 'recentPost' | translate }}</h3>
                              <ul>
                                 <li
                                    v-for="blog in lastPosts" :key="blog.blogId"
                                    @click.stop="openBlog(blog.blogId)"
                                    class="cp">
                                    <figure class="post-thumb">
                                        <img
                                          :src="blog.imageUrl" alt="Jablanica"
                                          class="footer-galery-image">
                                    </figure>
                                    <div class="post-content">
                                       <h6>
                                          {{isCurrentLanguageBs ? blog.title : blog.titleEn}}
                                       </h6>
                                       <div class="entry-meta">
                                          <span class="posted-on">
                                             {{ convertDate(blog.dateAdded) }}
                                          </span>
                                       </div>
                                    </div>
                                 </li>
                              </ul>
                           </aside>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <aside class="widget widget_text">
                              <h3 class="widget-title">{{ 'contactUs' | translate }}</h3>
                              <div class="textwidget widget-text">
                                 <p>{{ 'feelFreeContact' | translate }} !!</p>
                                 <ul>
                                    <li>
                                       <a href="tel:+38762376719">
                                          <i aria-hidden="true" class="fa fa-phone"></i>
                                          +38762 376 719
                                       </a>
                                    </li>
                                    <li>
                                       <a href="mailtop:info@domain.com">
                                          <i aria-hidden="true" class="fa fa-envelope"></i>
                                          info@visitjablanica.com
                                       </a>
                                    </li>
                                    <li>
                                       <i aria-hidden="true" class="fa fa-map-marker"></i>
                                       Trg Oslobođenja SP 32, <br>
                                       Jablanica , Bosnia and Herzegovina
                                    </li>
                                 </ul>
                              </div>
                           </aside>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                           <aside class="widget">
                              <h3 class="widget-title">{{ 'gallery' | translate }}</h3>
                              <div class="gallery gallery-colum-3">
                                 <figure class="gallery-item"
                                    v-for="(image, index) in gallery" :key="index">
                                    <router-link to="/gallery">
                                       <img
                                          :src="image.url"
                                          alt="Visit Jablanica" class="footer-galery-image">
                                    </router-link>
                                 </figure>
                              </div>
                           </aside>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="bottom-footer">
               <div class="container">
                  <div class="copy-right text-center">
                    Copyright &copy; 2022 Visit Jablanica. All rights reserved.</div>
               </div>
            </div>
         </footer>
         <!-- ***site footer html end*** -->
</template>
<script>
import convertDate from '@/mixins/dateConvert';

export default {
  name: 'Footer',

  mixins: [convertDate],

  computed: {
    gallery() {
      return this.$store.getters.galleryImages.slice(-6);
    },
    lastPosts() {
      return this.$store.getters.blogs.slice(-2);
    },
  },

  methods: {
    openBlog(id) {
      this.$router.push({ name: 'blog', params: { id } });
    },
  },
};
</script>
<style scoped>
.post-thumb {
   width: 95px !important;
}
h6 {
   color: #ffffff;
}
.footer-galery-image {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
}
</style>
