/**
 * Main vuex mutations
 * @packageDocumentation
 * @category Store
 */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import { RootState as State } from '@/types';
import getDefaultState from './state';

const mutations: MutationTree<State> = {
  setSliderImages(state, images) {
    Vue.set(state, 'sliderImages', images);
  },
  setRecommendedTours(state, tours) {
    Vue.set(state, 'recommendedTours', tours);
  },
  setTours(state, tours) {
    Vue.set(state, 'tours', tours);
  },
  setCurrentTourId(state, id) {
    Vue.set(state, 'currentTourId', id);
  },
  addTourOnFlatTours(state, tour) {
    Vue.set(state.flatTours, tour.tourId, tour);
  },
  setFoodPackages(state, payload) {
    Vue.set(state, 'foodPackages', payload);
  },
  setActivities(state, payload) {
    Vue.set(state, 'activities', payload);
  },
  setActivityLevels(state, payload) {
    Vue.set(state, 'activityLevels', payload);
  },
  setBlogs(state, payload) {
    Vue.set(state, 'blogs', payload);
  },
  setCurrentBlog(state, payload) {
    Vue.set(state, 'currentBlog', payload);
  },
  setCurrentBlogId(state, payload) {
    Vue.set(state, 'currentBlogId', payload);
  },
  setFlatBlogs(state, payload) {
    Vue.set(state, 'flatBlogs', payload);
  },
  setGalleryImages(state, payload) {
    Vue.set(state, 'galleryImages', payload);
  },
  localAddReview(state, payload) {
    state.flatTours[payload.tourId].reviews.push(payload);
  },
  setCustomizeTourSidebar(state, payload) {
    Vue.set(state, 'showCustomizeTour', payload);
  },
};

export default mutations;
