import { RootState as IState } from '@/types';

const getDefaultState = (): IState => ({
  routes: {
    local: {
      root: 'https://api-dev.visitjablanica.com',
    },
  },
  sliderImages: [],
  recommendedTours: [],
  tours: [],
  currentTour: {},
  flatTours: {},
  currentTourId: 0,
  activityLevels: {},
  foodPackages: {},
  tourCategories: {
    1: {
      id: 1,
      name: 'Jednodevna tura',
      nameEn: 'One-day offer',
    },
    2: {
      id: 2,
      name: 'Višednevna tura',
      nameEn: 'Multy-day offer',
    },
  },
  activities: {},
  blogs: [],
  flatBlogs: {},
  currentBlog: {},
  currentBlogId: 0,
  galleryImages: [],
  showCustomizeTour: false,
  socialUrls: {
    facebook: 'https://www.facebook.com/visitjablanicaa?mibextid=ZbWKwL',
    instagram: 'https://www.instagram.com/invites/contact/?i=byt6cq0dxch1&utm_content=45d0458',
    youtube: 'https://youtube.com/@visitjablanica',
    tripadvisor: 'https://www.tripadvisor.com/Attraction_Review-g1632097-d15224616-Reviews-Visit_Jablanica-Jablanica_Herzegovina_Neretva_Canton_Federation_of_Bosnia_and_H.html',
  },
});

export default getDefaultState;
