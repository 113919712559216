import { GetterTree } from 'vuex';
import { RootState as State } from '@/types';

export default {
  getRoutes: (state) => state.routes.local,
  sliderImages: (state) => state.sliderImages || [],
  recommendedTours: (state) => state.recommendedTours || [],
  tours: (state) => state.tours || [],
  flatTours: (state) => state.flatTours || {},
  currentTourId: (state) => state.currentTourId,
  currentTour: (state, getters) => state.flatTours[getters.currentTourId] || {},
  tourCategory: (state) => (id: string | number) => state.tourCategories[id] || {},
  foodPackages: (state) => (id: string | number) => state.foodPackages[id] || {},
  activityLevels: (state) => (id: string | number) => state.activityLevels[id] || {},
  activities: (state) => (id: string | number) => state.activities[id] || {},
  blogs: (state) => state.blogs || [],
  currentBlog: (state, getters) => state.flatBlogs[getters.currentBlogId] || {},
  currentBlogId: (state) => state.currentBlogId,
  flatBlogs: (state) => state.flatBlogs || {},
  galleryImages: (state) => state.galleryImages || [],
  showCustomizeTour: (state) => state.showCustomizeTour,
  social: (state) => state.socialUrls || {},
} as GetterTree<State, any>;
