import { LanguagesState as IState } from '@/types';

const State: IState = {
  currentLanguage: 'bs',
  translations: {
    en: {
      // header
      home: 'Home',
      about: 'About Us',
      tours: 'Tours',
      aboutJablanica: 'About Jablanica',
      contact: 'Contact',
      gallery: 'Gallery',
      bookNow: 'Book now',
      forFurtherInquiries: 'For Further Inquiries',
      searchDestination: 'Search Destination',
      maxNumber: 'Max Number',
      checkinDate: 'Checkin Date',
      checkoutDate: 'Checkout Date',
      enterDestination: 'Enter Destination',
      noOfPeople: 'No.of People',
      inquireNow: 'Check availability',
      customizedTour: 'Customized Tour',
      blog: 'Blog',

      // home
      checkoutOurTours: 'Things to do in Jablanica',
      reviews: 'reviews',
      perPerson: 'per person',
      viewAllTours: 'View all tours',
      areYouReady: 'ARE YOU READY TO ADVENTURE? REMEMBER US',
      learnMore: 'learn more',
      viewTours: 'view tours',
      customizedTourInfo: 'Plan the trip together with our experts. We will help you to create the best adventure for you.',
      homeToursInfo: 'Choose one of our offers and discover all the charms that this beautiful region has to offer.',
      homeGalleryInfo: 'Guest memories. We love our Guests and how they love our area. Keep sending us your beutiful photos of your vacation in Jablanica.',
      homeBlogInfo: 'We are looking forward to sharing current and exciting news and information about Jablanica',
      satisfiedCustomers: 'Satisfied customers',
      activeMembers: 'Active members',
      tourDestination: 'Tour destination',
      travelGuides: 'Travel guides',

      // gallery
      photosTravellers: "'PHOTO'S FROM TRAVELLERS",
      photoGallery: 'photo gallery',

      // BLOG
      latestBlog: 'LATEST BLOG',
      ourRecentPosts: 'Our recent posts',
      viewAllBlog: 'VIEW ALL BLOG',
      readMore: 'READ MORE',
      recentPost: 'RECENT POST',

      // contact
      contactUs: 'CONTACT US',
      readyForUnforgatable: 'READY FOR UNFORGETTABLE ADVENTURE. REMEMBER US',
      fellFreeToContact: 'Be free to contact us!',
      contactHomeTitle: 'Book your unforgettable tour.',
      contactHomeInfo: 'Visit some of the most beautiful regions of Bosnia and Herzegovina',
      contactDesc: 'We are looking forward to hearing from you. Please fill out the form below and we will get back to you as soon as possible. You can also contact us by email or directly via whatsapp and sms.',
      getInTouch: 'GET IN TOUCH',
      emailAddress: 'EMAIL ADDRESS',
      phoneNumber: 'PHONE NUMBER',
      addressLocation: 'ADDRESS & LOCATION',

      // footer
      feelFreeContact: 'Feel free to contact and reach us',
      subscribeOurNews: 'Subscribe our newsletter for more update & news',
      subscribe: 'SUBSCRIBE',
      enterEmail: 'Enter your email',
      footerDescription: 'Our team specializes in providing tourism services, especially outdoor activities, such as cycling, canoeing, rafting on Lake Jablanica, camping, hiking, motocross and other adrenaline-based activities.',

      // tour
      tourDetails: 'Tour details',
      minYear: 'Min. Year',
      maxYear: 'Max. Year',
      maxNoPerson: 'Max. No. Person',
      minNoPerson: 'Min. No. Person',
      duration: 'Duration',
      recomendedPeriod: 'Recomended period',
      whatToBring: 'What to bring',
      startPlace: 'Start place',
      finishPlace: 'Finish place',
      equipments: 'Equipments',
      guide: 'Guide',
      transport: 'Transport',
      tourCategory: 'Tour category',
      activityLevel: 'Activity level',
      foodPackage: 'Food package',
      hours: 'hours',
      days: 'days',
      yes: 'Yes',
      no: 'No',
      tourReviews: 'Reviews',
      addReview: 'Leave review',
      reviewInfoMessage: 'Your email address will not be published. Required fields are marked *',
      checkAvailabilityForThisTour: 'Check availability for this tour. Write your phone number and email address. We will contact you as soon as possible.',
      recomendedTours: 'Recomended tours',

      // about text
      aboutText: 'Our team is specialized in providing tourist services, especially outdoor activities such as cycling, canoeing, rafting on the Jablanica Lake, camping, hiking, motocross and other adrenaline-based activities. In cooperation with our partners, we will make your holiday unforgettable. We welcome you to join us in some of these activities. We are at your disposal for any further information. We are looking forward to seeing you in Jablanica.',
      whoWeAre: 'WHO WE ARE',
      aboutTitle: 'How we are the best option',
      affordableTours: 'The most affordable tours',
      affordableToursInfo: 'Our tours are among the cheapest in the region.',
      bestGuides: 'Best tour guides',
      bestGuidesInfo: 'An experienced team of young local people.',
      ecoFriendly: 'Eco-friendly',
      ecoFriendlyInfo: 'Our tours are environmentally friendly and include local products.',

      // toast messagess
      reviewAdded: 'Review added successfully',
      reviewNotAdded: 'Error on adding review. Please try again later',
      tourAvailabilityQuestioMessage: 'You have successfully sent a request for tour availability. We will contact you as soon as possible.',
      tourAvailabilityQuestioErrorMessage: 'Error on sending request for tour availability. Please try again later.',
      contactMessageSuccess: 'You have successfully sent a message. We will contact you as soon as possible.',
      contactMessageError: 'Error on sending message. Please try again later.',

      // form validation
      requiredValid: 'This field is required',
      emailValid: 'Please enter valid email',

      // forms
      name: 'Name',
      email: 'Email',
      message: 'Message',
      send: 'Send',
      yourRating: 'Your rating',
      yourReview: 'Your review',
      submit: 'Submit',
      postReview: 'Post review',
      city: 'City',
      country: 'Country',
      yourName: 'Your name',
      yourEmail: 'Your email',
      yourPhone: 'Your phone',
      personNo: 'Number of persons',
      startDate: 'Start date',
      checkAvailability: 'Check availability',
      pleaseSelectTour: 'Please select a tour',
      emailOrPhone: 'Email or phone',
      sendMessage: 'Send message',
      contactMessagePlaceholder: 'Write your message here',
      customizedMessage: 'Describe what kind of tour you want',
    },
    bs: {
      // header
      home: 'Početna',
      about: 'O nama',
      tours: 'Ture',
      aboutJablanica: 'O Jablanici',
      contact: 'Kontakt',
      gallery: 'Galerija',
      bookNow: 'Rezerviši odmah',
      forFurtherInquiries: 'Za daljnja pitanja',
      searchDestination: 'Traži turu',
      maxNumber: 'Max broj',
      checkinDate: 'Datum početka',
      checkoutDate: 'Datum kraja',
      enterDestination: 'Unesi turu',
      noOfPeople: 'Broj ljudi',
      inquireNow: 'Provjerite dostupnost',
      customizedTour: 'Prilagođena tura',
      blog: 'Članci',

      // home
      checkoutOurTours: 'Šta raditi u Jablanici',
      reviews: 'recenzije',
      perPerson: 'po osobi',
      viewAllTours: 'Pogledajte sve ture',
      areYouReady: 'DA LI STE SPREMNI ZA AVANTURU? ZAPAMTITE NAS',
      learnMore: 'nauči više',
      viewTours: 'pregledaj ture',
      customizedTourInfo: 'Planirajte putovanje zajedno sa našim stručnjacima. Pomoći ćemo vam da kreirate najbolju avanturu za vas.',
      homeToursInfo: 'Odaberite neku od naših ponuda i otkrijte sve čari koje nudi ovaj prelijepi kraj.',
      homeGalleryInfo: 'Uspomene gostiju. Volimo naše goste i kako oni vole naš kraj. Šaljite nam i dalje svoje predivne fotografije vašeg odmora u Jablanici.',
      homeBlogInfo: 'Radujemo se što ćemo podijeliti aktualne i uzbudljive vijesti i informacije o Jablanici',
      satisfiedCustomers: 'Zadovoljni klijenti',
      activeMembers: 'Aktivni članovi',
      tourDestination: 'Odredišta tura',
      travelGuides: 'Turistički vodiči',

      // gallery
      photosTravellers: 'FOTOGRAFIJE PUTNIKA',
      photoGallery: 'GALERIJA SLIKA',

      // BLOG
      latestBlog: 'ZADNJI BLOG',
      ourRecentPosts: 'Naše nedavne objave',
      viewAllBlog: 'PREGLEDAJ SVE OBJAVE',
      readMore: 'PROČITAJ VIŠE',
      recentPost: 'NEDAVNA OBJAVA',

      // contact
      contactUs: 'KONTAKTIRAJTE NAS',
      readyForUnforgatable: 'SPREMNI ZA NEZABORAVNA AVANTURU. ZAPAMTITE NAS',
      fellFreeToContact: 'Budite slobodni i kontaktirajte nas!',
      contactHomeTitle: 'Rezervisite svoju nezaboravnu turu',
      contactHomeInfo: 'Posjetite neke od najljepših predjela Bosne i Hercegovine',
      contactDesc: 'Radujemo se Vašem odgovoru. Molimo Vas da popunite formular ispod i mi ćemo Vam se javiti u najkraćem mogućem roku. Možete nas kontaktirati i putem e-maila ili direktno putem WhatsApp-a i sms-a.',
      getInTouch: 'STUPIMO U KONTAKT',
      emailAddress: 'EMAIL ADRESA',
      phoneNumber: 'BROJ TELEFONA',
      addressLocation: 'ADRESA I LOKACIJA',

      // footer
      feelFreeContact: 'Budite slobodni kontaktirati nas',
      subscribeOurNews: 'Pretplatite se na naš newsletter za više ažuriranja i novosti',
      subscribe: 'PRETPLATITE SE',
      enterEmail: 'Unesite vaš email',
      footerDescription: 'Naš tim specijaliziran je za pružanje turističkih usluga, posebno aktivnosti na otvorenom, kao što su vožnja biciklom, kanu, rafting na jezeru Jablanica, kampiranje, planinarenje, motocross i druge aktivnosti na bazi adrenalina.',

      // tour
      tourDetails: 'Detalji ture',
      minYear: 'Min. Godina',
      maxYear: 'Max. Godina',
      maxNoPerson: 'Max. broj osoba',
      minNoPerson: 'Min. broj osoba',
      duration: 'Trajanje',
      recomendedPeriod: 'Preporučeni period',
      whatToBring: 'Šta ponjeti',
      startPlace: 'Početna lokacija',
      finishPlace: 'Završna lokacija',
      equipments: 'Oprema',
      guide: 'Vodič',
      transport: 'Prevoz',
      tourCategory: 'Tip',
      activityLevel: 'Nivo aktivnosti',
      foodPackage: 'Obrok',
      hours: 'sati',
      days: 'dana',
      yes: 'Da',
      no: 'Ne',
      tourReviews: 'Recenzije',
      addReview: 'Ostavi recenziju',
      reviewInfoMessage: 'Vaša email adresa neće biti objavljena. Obavezna polja su označena *',
      checkAvailabilityForThisTour: 'Provjerite dostupnost za ovu turu. Unesite vaš broj telefona i email adresu. Kontaktiraćemo vas u najkraćem mogućem roku.',
      recomendedTours: 'Preporučene ture',

      // about text
      aboutText: 'Naš tim specijaliziran je za pružanje turističkih usluga, posebno aktivnosti na otvorenom, kao što su vožnja biciklom, kanu, rafting na jezeru Jablanica, kampiranje, planinarenje, motocross i druge aktivnosti na bazi adrenalina. U saradnji s našim partnerima učinićemo Vaš odmor nezaboravnim. Pozdravljamo vas da nam se pridružite u nekoj od ovih avantura i provedite nezaboravan odmor s našom agencijom!',
      whoWeAre: 'KO SMO MI',
      aboutTitle: 'Kako smo mi najbolja opcija',
      affordableTours: 'Najpovoljnije ture',
      accessableToursInfo: 'Naše ture su među najjeftinijim u regionu.',
      bestGuides: 'Najbolji turistički vodiči',
      bestGuidesInfo: 'Iskusan tim mladih lokalnih ljudi.',
      ecoFriendly: 'Eco-friendly',
      ecoFriendlyInfo: 'Naše ture su ekološki prihvatljive i uključuju lokalne proizvode.',

      // toast messagess
      reviewAdded: 'Recenzija uspješno dodana.',
      reviewNotAdded: 'Greška prilikom dodavanja recenzije. Molimo pokušajte ponovo kasnije.',
      tourAvailabilityQuestioMessage: 'Uspješno ste poslali upit za dostupnost ture. U najkraćem roku ćemo Vas kontaktirati.',
      tourAvailabilityQuestioMessageError: 'Greška prilikom slanja upita za dostupnost ture. Molimo pokušajte ponovo kasnije.',
      contactMessageSuccess: 'Uspješno ste poslali poruku. U najkraćem roku ćemo Vas kontaktirati.',
      contactMessageError: 'Greška prilikom slanja poruke. Molimo pokušajte ponovo kasnije.',

      // form validation
      requiredValid: 'Ovo polje je obavezno',
      emailValid: 'Molimo unesite validan email',

      // forms
      name: 'Ime i prezime',
      email: 'Email',
      message: 'Poruka',
      send: 'Pošalji',
      yourRating: 'Vaša ocjena',
      yourReview: 'Vaša recenzija',
      submit: 'Pošalji',
      postReview: 'Objavi recenziju',
      city: 'Grad',
      country: 'Država',
      yourName: 'Vaše ime',
      yourEmail: 'Vaš email',
      yourPhone: 'Vaš telefon',
      personNo: 'Broj osoba',
      startDate: 'Datum početka',
      checkAvailability: 'Provjerite dostupnost',
      pleaseSelectTour: 'Molimo odaberite turu',
      emailOrPhone: 'Email ili telefon',
      sendMessage: 'Pošalji poruku',
      contactMessagePlaceholder: 'Napišite poruku ovdje',
      customizedMessage: 'Opišite kakvu turu želite',
    },
  },
};

export default State;
