<!-- eslint-disable no-tabs -->
<template>
	<div class="contact-from-wrap primary-bg">
		<form class="contact-from" @submit.prevent="sendMessage">
				<p>
					<input type="text"
							v-model="name" name="name"
							:placeholder="translate('yourName')" v-validate="'required'">
					<span
						v-show="errors.has('name')"
						class="invalid-feedback">
						{{ translate('requiredValid') }}
					</span>
				</p>
				<p>
					<input type="email" name="email"
							v-model="email" :placeholder="translate('yourEmail')" v-validate="'required|email'">
					<span v-show="errors.has('email')"
						class="invalid-feedback">
						{{ translate('emailValid') }}</span>
				</p>
				<p>
					<textarea rows="6" name="comment" v-model="comment"
						:placeholder="translate(messagePlaceholder)"
					v-validate="'required'"></textarea>
					<span
						v-show="errors.has('comment')"
						class="invalid-feedback">
						{{ translate('requiredValid') }}
					</span>
				</p>
				<p>
					<input type="submit" name="submit" :value="translate('sendMessage')">
				</p>
		</form>
	</div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactForm',

  props: {
    messagePlaceholder: {
      type: String,
      required: false,
      default: 'contactMessagePlaceholder',
    },
  },

  data() {
    return {
      name: '',
      email: '',
      comment: '',
    };
  },

  computed: {
  },

  methods: {
    sendMessage() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const templateParams = {
            from_name: this.name,
            from_email: this.email,
            message: this.comment,
          };

          emailjs.send('service_7hn4vhc', 'template_ejdpq6q', templateParams, 'b9Xjw4ic8AED2mAB_')
            .then(() => {
              this.$toast.success(this.translate('contactMessageSuccess'));
              // Reset form fields
              this.name = '';
              this.email = '';
              this.comment = '';
            }, () => {
              this.$toast.error(this.translate('contactMessageError'));
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.invalid-feedback {
  position: absolute;
  display: block;
  color: red;
  font-size: 14px;
}
.mx-datepicker {
  display: initial;
}
</style>
