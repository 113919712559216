export default {
  methods: {
    onPageLoad() {
      const loader = document.getElementById('loading-wrapper');
      if (!loader) return;
      const removeLoader = () => {
        loader.remove();
      };
      removeLoader();
      /* const animation = loader.animate(
          { opacity: 0 }, { duration: 200 },
        );
        animation.onfinish = removeLoader; */
    },
  },
};
