<!-- eslint-disable max-len -->
<template>
   <header id="masthead" class="site-header">
      <!-- header html start -->
      <div class="top-header">
         <div class="container">
            <div class="top-header-inner">
               <div class="header-contact text-left">
                  <a href="tel:+38762376719">
                     <i aria-hidden="true" class="fa fa-phone"></i>
                     <div class="header-contact-details">
                        <span class="contact-label">{{translate('forFurtherInquiries')}} :</span>
                        <h5 class="header-contact-no">+38762 376 719</h5>
                     </div>
                  </a>
               </div>
               <div class="site-logo text-center">
                  <h1 class="site-title">
                     <router-link to="/home">
                        <img src="../../assets/images/flogo.png" width="110" alt="Logo">
                     </router-link>
                  </h1>
               </div>
               <div class="header-icon text-right">
                  <div class="header-search-icon d-inline-block"
                     :class="{ 'isCurrentLanguage': isCurrentLanguageBs }">
                     <a @click="setLanguage('bs')">
                        <img src="../../assets/images/icon-bs.png" />
                     </a>
                  </div>
                  <div class="header-search-icon d-inline-block"
                     :class="{ 'isCurrentLanguage': isCurrentLanguageEn }">
                     <a @click="setLanguage('en')">
                        <img src="../../assets/images/icon-english.png" />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <fixed-header>
         <div class="bottom-header navbar">
            <div class="container" style="display: block">
               <div
                  class="bottom-header-inner d-flex justify-content-between align-items-center">
                  <div class="header-social social-icon">
                     <ul>
                        <li>
                           <a :href="social.facebook" target="_blank">
                              <i class="fab fa-facebook-f" aria-hidden="true"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="social.instagram" target="_blank">
                              <i class="fab fa-instagram" aria-hidden="true"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="social.youtube" target="_blank">
                              <i class="fab fa-youtube" aria-hidden="true"></i>
                           </a>
                        </li>
                        <li>
                           <a :href="social.tripadvisor" target="_blank">
                              <i class="fab fa-tripadvisor" aria-hidden="true"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div class="navigation-container d-none d-lg-block">
                     <nav id="navigation" class="navigation">
                        <ul>
                           <li>
                              <router-link to="/home">{{translate('home')}}</router-link>
                           </li>
                           <li>
                              <router-link to="/about">{{translate('about')}}</router-link>
                           </li>
                           <li>
                              <router-link to="/tours">{{translate('tours')}}</router-link>
                           </li>
                           <li>
                              <router-link to="/blogs">{{translate('blog')}}</router-link>
                           </li>
                           <li>
                              <router-link to="/contact">{{translate('contact')}}</router-link>
                           </li>
                           <li>
                              <router-link to="/gallery">{{translate('gallery')}}</router-link>
                           </li>
                        </ul>
                     </nav>
                  </div>
                  <div class="header-btn cp">
                     <a @click.stop="showCustomizedTourSidebar"
                        class="round-btn">{{translate('customizedTour')}}</a>
                  </div>
               </div>
            </div>
         </div>
      </fixed-header>
      <div class="mobile-menu-container">
         <div class="slicknav_menu">
         <a @click.stop="toogleMobileMenu" aria-haspopup="true" role="button" tabindex="0" class="slicknav_btn slicknav_collapsed" style="outline: none;">
            <span class="slicknav_menutxt">Menu</span>
            <span class="slicknav_icon"><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span><span class="slicknav_icon-bar"></span></span>
         </a>
         <nav class="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" v-if="showMobileManu">
         <ul>
            <li>
               <router-link to="/home">{{translate('home')}}</router-link>
            </li>
            <li>
               <router-link to="/about">{{translate('about')}}</router-link>
            </li>
            <li>
               <router-link to="/tours">{{translate('tours')}}</router-link>
            </li>
            <li>
               <router-link to="/blogs">{{translate('blog')}}</router-link>
            </li>
            <li>
               <router-link to="/contact">{{translate('contact')}}</router-link>
            </li>
            <li>
               <router-link to="/gallery">{{translate('gallery')}}</router-link>
            </li>
         </ul>
      </nav>
   </div>
</div>
   </header>
</template>
<script>
// import LanguageDropdown from '@/components/widgets/LanguageDropdown';
import FixedHeader from 'vue-fixed-header';

export default {
  name: 'Header',
  components: {
    // LanguageDropdown,
    FixedHeader,
  },
  data() {
    return {
      showLangDropdown: false,
      showMobileManu: false,
    };
  },
  watch: {
    '$route.path': {
      handler() {
        if (this.showMobileManu) {
          this.showMobileManu = false;
        }
      },
    },
  },
  computed: {
  },
  methods: {
    toogleMobileMenu() {
      this.showMobileManu = !this.showMobileManu;
    },
    showCustomizedTourSidebar() {
      this.$store.dispatch('setCustomizeTourSidebar', true);
    },
    setLanguage(key) {
      if (key === this.currentLanguage) return;
      this.changeLanguage(key);
    },
  },
};
</script>
<style>
.navbar>.container {
   display: block;
}

.isCurrentLanguage {
   background-color: #FFFFFF;
   border-radius: 50%;
}
</style>
