import { GetterTree } from 'vuex';
import { LanguagesState as State } from '@/types';

const getters: GetterTree<State, any> = {
  /** Returns the key of currently selected language */
  currentLanguage: (state) => state.currentLanguage,
  /** Returns all the available languages and translations */
  translations: (state) => state.translations,
  /** Returns the translated text for passed translation key argument
    * ```
    * state.translations.bs.username: 'Korisničko ime'
    *
    * let translationKey = 'username';
    * const translation = this.$store.getters.translate(translatinKey);
    * console.log(translation); // outputs 'Korisničko ime'
    * ```
   */
  translate: (state) => (key: string) => (state.translations[state.currentLanguage] || {})[key]
       || state.translations.en[key]
       || key,
};

export default getters;
