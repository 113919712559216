import Vue from 'vue';
import VeeValidate from 'vee-validate';
// @ts-ignore
import App from '@/components/pages/App';
import { translate } from '@/utilities/filters';
import { languages } from '@/mixins/';
import social from '@/mixins/social';
import removeLoader from '@/mixins/removeLoader';
import Toast from 'vue-toastification';
import router from './router';
import store from './store';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

Vue.mixin(languages);
Vue.mixin(social);
Vue.mixin(removeLoader);
Vue.filter('translate', translate);
Vue.config.productionTip = false;

Vue.use(Toast);
Vue.use(VeeValidate);
Vue.use(require('vue-moment'));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
